import Vue from 'vue'

export default {
  methods: {
    async resetStorage() {
      if (this.$route.name !== 'login') {
        await this.$store.dispatch('setRecordsToSync')
      }
      const recordsToSyncCount = Vue.store.getters.recordsToSync

      let message = 'Are you sure you wish to delete all records?'
      let message2 = 'Are you sure you wish to delete local storage?'

      if (recordsToSyncCount > 0) {
        message = `You have ${recordsToSyncCount} unsynced record(s)!<br><br>`
        message += '<p style="color: red;"><strong>PROCEEDING WILL PERMANENTLY DELETE ALL YOUR RECORDS! THIS PROCESS IS IRREVERSIBLE!</strong></p>'
        message2 = 'Confirm below to delete all records from this device.'
      }

      try {
        const result = await this.$root.confirm({
          title: 'Delete All Records?',
          titleIconColor: 'error',
          body: message,
          confirm: 'Delete All My Records!',
          confirmColor: 'error',
          html: true,
        })

        if (result === true) {
          const confirmation = await this.$root.confirm({
            title: 'Confirm Storage Deletion',
            titleIconColor: 'error',
            body: message2,
            confirm: 'Delete Local Storage',
            confirmColor: 'error',
            isResetStorageConfirmation: recordsToSyncCount > 0,
          })

          if (confirmation === true) {
            // Check if already on the login page to avoid unnecessary navigation
            if (this.$route.name !== 'login') {
              await this.$router.push({ name: 'login' })
            }
            await this.$store.dispatch('resetState')

            // Show success confirmation dialog
            this.$root.confirm({
              title: 'Reset Local Storage',
              titleIconColor: 'info',
              subTitle: 'All records have been deleted!',
              body: 'Local storage reset successful!',
              confirm: 'OK',
              cancel: false,
              confirmColor: 'info',
              persistent: true,
            }).then(() => {
              window.location.reload();
            })

          }
        }
      } catch (err) {
        console.error('Error in resetStorage:', err)
      }
    },
  },
}
