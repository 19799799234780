function arrayBufferToBase64(buffer) {
  const bytes = new Uint8Array(buffer)
  let binary = ''
  const chunkSize = 8192 // Process in chunks to avoid stack overflow
  for (let i = 0; i < bytes.length; i += chunkSize) {
    binary += String.fromCharCode.apply(null, bytes.subarray(i, i + chunkSize))
  }

  return window.btoa(binary)
}

function base64ToArrayBuffer(base64) {
  const binaryString = window.atob(base64) // Transform into a binary string (each character represents a byte)
  const len = binaryString.length
  const bytes = new Uint8Array(len) // Generate typed Array of 8-bit unsigned ints

  // Iterate over binaryString and return the UTF-16 code of the character at index i.
  // eslint-disable-next-line
  for (let i = 0; i < len; i++) {
    // Store it inside the Uint8Array at the corresponding index.
    bytes[i] = binaryString.charCodeAt(i)
  }

  return bytes.buffer // Return the ArrayBuffer
}

async function importKeyFromBase64(base64Key) {
  const rawKey = base64ToArrayBuffer(base64Key)
  if (rawKey.byteLength !== 16 && rawKey.byteLength !== 32) {
    throw new Error('AES key must be 128 or 256 bits')
  }

  return crypto.subtle.importKey(
    'raw', // Specifies that the key provided is in raw binary form
    rawKey, // Pass ArrayBuffer key
    { name: 'AES-GCM' }, // Encryption method
    true, // It's extractable (can be exported later)
    ['encrypt', 'decrypt'], // Methods allowed
  )
}

export async function getEncryptionKey() {
  try {
    const encryptionKey = process.env.VUE_APP_ENCRYPTION_KEY
    if (!encryptionKey) throw new Error('Encryption key not found in environment')

    // Convert Base64 stored key into ArrayBuffer
    return importKeyFromBase64(encryptionKey)
  } catch (error) {
    console.error('Error generating key: ', error)

    return null
  }
}

export async function encryptData(key, data) {
  if (key && data) {
    const iv = crypto.getRandomValues(new Uint8Array(12)) // Initialization vector
    const encoded = new TextEncoder().encode(JSON.stringify(data)) // Convert data to JSON string for encoding

    // Encrypt data with the corresponding key
    const encrypted = await crypto.subtle.encrypt(
      { name: 'AES-GCM', iv: iv },
      key,
      encoded,
    )

    // Convert ArrayBuffer to Base64 string for storage
    return { iv: arrayBufferToBase64(iv), encrypted: arrayBufferToBase64(encrypted) }
  }

  return null
}

export async function decryptData(key, iv, encrypted) {
  if (key && iv && encrypted) {
    const ivArrayBuffer = base64ToArrayBuffer(iv)
    const encryptedArrayBuffer = base64ToArrayBuffer(encrypted)

    const decrypted = await crypto.subtle.decrypt(
      { name: 'AES-GCM', iv: ivArrayBuffer },
      key,
      encryptedArrayBuffer,
    )

    return JSON.parse(new TextDecoder().decode(decrypted)) // Decode bytes to string and parse JSON
  }

  return null
}

export function isEncrypted(data) {
  if (data?.iv && data?.encrypted) {
    // Check if iv is a Uint8Array of length 12 (needed for AES-GCM)
    if (data.iv instanceof Uint8Array && data.iv.length === 12) {
      // Check if encrypted is a Uint8Array
      if (data.encrypted instanceof Uint8Array && data.encrypted.byteLength > 0) {
        return true
      }
    }
  }

  return false
}
